/* Container principal do MenuFilter */
.menu-filter-container {
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    font-family: 'Sant', sans-serif;
    border-radius: 10px;
    width: 100%;
    padding: 0;
    gap: 2px;
    box-shadow: 0px 2px 6px 0px #00000040;
  }

  
  /* Estilos para os botões */
  .menu-filter-button {
    border-radius: 8px;
    padding-right: 8px;
    padding-left: 8px;
    gap: 4px;
    border: none;
    background-Color: #FFFFFF;
    transition: all 0.1s ease;
  }
  
  /* Estilos para o botão de "AllLabel" */
  .menu-filter-button-all {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding-right: 8px;
    padding-left: 8px;
    gap: 4px;
    box-shadow: 0px 2px 6px 0px #00000040;
    border: none;
    position: relative;
    z-index: 1;
  }

  .menu-filter-button:hover {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .menu-filter-button-all:hover {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  
  .menu-filter-icon {
    margin-right: 8px;
    margin-bottom: 4px;
  }
  