.card-pie {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: linear-gradient(129.81deg, #F56036 7.58%, #F5365C 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.header-pie {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title-pie {
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 14px;
  line-height: 19.07px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.content-pie {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.chartContainer-pie {
  width: 200px;
  height: 180px;
}

.buttons-pie {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-right: 10px;
}

.button-pie {
  width: 111.52px;
  height: 34px;
  border-radius: 8px;
  padding: 6px 16px;
  background-color: #C4C4C4;
  color: #172B4D;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected{
  background-color: #FFFFFF;
}

@media (min-width: 982px) and (max-width: 992px) {
  .button-pie {
    width: 80.52px;
    height: 18px;
  }

  .button-label {
    font-size: 10px;
  }

  .chartContainer-pie {
    width: 90px;
    height: 100px;
  }

  div.content-pie{
    padding-left: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1010px) {
  .button-pie {
    width: 80.52px;
    height: 18px;
  }

  .button-label {
    font-size: 10px;
  }

  .chartContainer-pie {
    width: 90px;
    height: 100px;
  }

  div.content-pie{
    padding-left: 0px;
  }
}

@media (min-width: 1010px) and (max-width: 1061px) {
  .button-pie {
    width: 84.52px;
    height: 22px;
  }

  .button-label {
    font-size: 10px;
  }

  .chartContainer-pie {
    width: 100px;
    height: 110px;
  }

  div.content-pie{
    padding-left: 0px;
  }
}

@media (min-width: 1061px) and (max-width: 1135px) {
  .button-pie {
    width: 88.52px;
    height: 25px;
  }

  .button-label {
    font-size: 11px;
  }

  .chartContainer-pie {
    width: 120px;
    height: 130px;
  }

  div.content-pie{
    padding-left: 0px;
  }
}

@media (min-width: 1135px) and (max-width: 1200px) {
  .button-pie {
    width: 94.52px;
    height: 28px;
  }

  .button-label {
    font-size: 13px;
  }

  .chartContainer-pie {
    width: 130px;
    height: 140px;
  }
}

@media (min-width: 1200px) and (max-width: 1261px) {
  .button-pie {
    width: 94.52px;
    height: 28px;
  }

  .button-label {
    font-size: 13px;
  }

  .chartContainer-pie {
    width: 150px;
    height: 160px;
  }
}

@media (min-width: 1261px) and (max-width: 1316px) {
  .button-pie {
    width: 94.52px;
    height: 28px;
  }

  .button-label {
    font-size: 13px;
  }

  .chartContainer-pie {
    width: 180px;
    height: 170px;
  }
}

@media (min-width: 1316px) and (max-width: 1350px) {
  .button-pie {
    width: 100.52px;
    height: 30px;
  }

  .button-label {
    font-size: 13px;
  }
}