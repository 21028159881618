.post-insta{
    height: 500px;
    width: 30%;
    flex-shrink: 0;
}

.generic-graph{
    max-height: 150px;
    width: 100%;
}

.content-post{
    width: 100%;
    height: 100%;
}

.container-generic-graph {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.pie-chart{
    width: 100%;
}

.line-pie-chart{
    justify-content: space-between;
}

div.content-pie{
    padding-left: 10px;
}

.income-first{
    width: 300px;
    height: 227px;
}

.productivity-first{
    width: 500px;
}

.higher-income-graph{
    width: 100%;
}

.img-post{
    width: 100%;
    height: 100%;
}

.container-footer{
    max-height: 500px;
}

@media (max-width: 982px) {
    .line-pie-chart {
      flex-direction: column;
    }

    .pie-chart{
        width: 210%;
    }

    .pie-chart-2{
        margin-top: 30px;
        margin-bottom: 30px;
    }

    div.content-pie{
        justify-content: space-between;
    }
}

@media (max-width: 991px) {
    .post-insta{
        height: 690px;
        width: 655px;
        align-items: center;
        margin-bottom: 40px;
    }

    .content-post{
        margin-left: 6px;
    }

    .col-income-first{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .productivity-first{
        height: 177px;
    }
}

@media (min-width: 982px) and (max-width: 992px){
    .card-pie, div.pie-chart{
        width: 200px;
    }

    .col-sm-6{
        flex: 0 0 30% !important;
    }

    #col-pie-2{
        margin-left: 0px;
    }

    .line-pie-chart{
        flex-wrap: nowrap;
    }
}

@media (max-width: 994px) {
    .post-insta{
        height: 690px;
        width: 655px;
        align-items: center;
        margin-bottom: 40px;
    }

    .content-post{
        margin-left: 6px;
    }
}

@media (min-width: 994px) {
    .post-insta{
        height: 650px;
        width: 95%;
        margin-bottom: 40px;
    }

    .content-post{
        margin-left: 6px;
    }
}


@media (min-width: 1000px) {
    .content-post{
        height: 460px;
    }

    .generic-graph{
        height: 5%;
        width: 98.5%;
    }

    .post-insta{
        height: 450px;
        width: 30%;
        margin-right: 15px;
        margin-bottom: 0px !important;
    }

    .container-footer{
        margin-bottom: 0px !important;
    }
}

@media (min-width: 1200px) {
    .generic-graph{
        width: 98.5%;
    }

    .g1{
        margin-bottom: 45px !important;
    }

    .post-insta, .content-post{
        height: 470px;
    }
}

@media (min-width: 1300px) {
    .content-post{
        height: 460px;
    }

    .generic-graph{
        height: 5%;
        width: 98.5%;
    }

    .post-insta{
        height: 450px;
        width: 30%;
        margin-right: 15px;
        margin-bottom: 0px !important;
    }

    .container-footer{
        margin-bottom: 0px !important;
    }
}


@media (min-width: 1450px) {
    .post-insta{
        height: 493px;
    }
}

@media (min-width: 1460px) {
    .post-insta{
        height: 459px;
    }
}

@media (min-width: 1500px) {
    .content-pie {
      flex-direction: row; 
      justify-content: flex-end;
    }
  
    .buttons-pie {
      flex: 0 0 auto;
      margin-right: auto;
    }
  
    .chartContainer-pie {
      flex: 0 0 53%;
    }

    .post-insta{
        height: 469px;
    }
}

@media (min-width: 1550px) {
    .post-insta{
        height: 479px;
    }
}

@media (min-width: 1580px) {
    .post-insta{
        height: 493px;
    }
}

@media (min-width: 1630px) {
    .post-insta{
        height: 503px;
    }
}

@media (min-width: 1670px) {
    .post-insta{
        height: 513px;
    }
}

@media (min-width: 1700px) {
    .post-insta{
        height: 523px;
    }
}


@media (min-width: 1730px) {
    .post-insta{
        height: 533px;
    }
}

@media (min-width: 1760px) {
    .post-insta{
        height: 543px;
    }
}

@media (min-width: 1800px) {
    .post-insta{
        height: 553px;
    }
}

@media (min-width: 1840px) {
    .post-insta{
        height: 563px;
    }
}

@media (min-width: 1840px) {
    .post-insta{
        height: 573px;
    }
}

@media (min-width: 1880px) {
    .post-insta{
        height: 583px;
    }
}

@media (min-width: 1880px) {
    .post-insta{
        height: 593px;
    }
}

@media (min-width: 1960px) {
    .post-insta{
        height: 603px;
    }
}