.border-card{
    border-radius: 25px;
}

.title{
    font-size: 12px;
}

.last-simulation{
    font-size: 20px;
}

.productivity-icon{
    width: 40px;
    height: 40px;
}