.graph-container {
    background-color: #212229;
    padding: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    color: white;
  }
  
  .income-title {
    margin-bottom: 10px;
  }
  
  .income-header {
    display: flex;
    align-items: center;
  }
  
  .currency-symbol {
    font-size: 24px;
  }
  
  .income-value {
    font-size: 36px;
    font-weight: bold;
    margin-left: 8px;
  }
  
  .income-label {
    font-size: 16px;
    margin-top: 4px;
  }
  
  .income-details {
    font-size: 14px;
  }
  
  .income-details .percentage {
    color: #48BB78;
  }
  
  .income-details .income-amount {
    color: #F6F9FC;
  }
  
  .generate-report {
    margin-top: 20px;
  }
  
  .generate-btn {
    width: 100%;
    padding: 10px;
    background-color: white;
    color: black;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.7s ease, color 0.7s ease; /* Suaviza a transição de cor */
  }
  
  .generate-btn:hover {
    background-color: #212229; /* Cor de fundo escura */
    color: white; /* Cor da fonte fica branca */
  }
  
  
  .progress-bars {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }
  
  .progress-section {
    width: 48%;
  }
  
  .progress-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .progress-title {
    font-size: 14px;
    text-align: left;
  }
  
  .progress-percentage {
    font-size: 14px;
    color: white;
    font-weight: bold;
    text-align: left;
  }
  
  .production-bar .progress-bar {
    background-color: #F9A825 !important; /* Cor laranja para a Produção */
  }
  
  .biomass-bar .progress-bar {
    background-color: #388E3C !important; /* Cor verde para a Biomassa */
  }
  
  .progress-bar {
    height: 8px;
    border-radius: 5px;
  }
  
  .progress-value {
    text-align: center;
    font-size: 12px;
  }

  @media (min-width: 992px) {
    .graph-container {
      height: 323px;
      width: 217px;
    }

    .progress-bars {
      margin-top: 60px;
    }

    .generate-report {
      margin-top: 20px;
    }
  }

  @media (min-width: 1001px) {
    .graph-container {
      height: 323px;
      width: 237px;
    }

    .progress-bars {
      margin-top: 60px;
    }

    .generate-report {
      margin-top: 20px;
    }
  }

  @media (min-width: 1042px) {
    .graph-container {
      height: 300px;
      width: 237px;
    }

    .progress-bars {
      margin-top: 50px;
    }

    .generate-report {
      margin-top: 10px;
    }
  }

  @media (min-width: 1400px) {
    .graph-container {
      height: 300px;
      width: 280px;
    }

    .progress-bars {
      margin-top: 60px;
    }

    .generate-report {
      margin-top: 20px;
    }
  }

  @media (min-width: 1600px) {
    .graph-container {
      width: 320px;
    }
  }

  @media (min-width: 1800px) {
    .graph-container {
      width: 350px;
    }
  }

  @media (min-width: 2000px) {
    .graph-container {
      width: 420px;
    }
  }
  