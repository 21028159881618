.icon-box {
    width: 45px;
    height: 45px;
    background-color: #5e72e4;
    margin-right: 10px;
  }
  
  .progress-container {
    width: 116px;
    position: relative;
    top: 6px;
  }
  
  .progress-percentage {
    position: absolute;
    top: -22px; /* Ajusta a posição da porcentagem para cima da barra */
    left: 16.5%;
    transform: translateX(-50%); /* Centraliza a porcentagem */ 
  }
  
  .custom-progress-bar {
    height: 8px; /* Barra mais fina */
    border-radius: 10px;
    background-color: #e5e5e5;
  }

  .font-size-20{
    font-size: 20px;
  }

  @media (max-width: 1332px) {
    .icon-box {
      display: none;
    }
  }

  @media (max-width: 1150px) {
    .progress-container {
      top: 32px;
    }
  }
  
  @media (max-width: 1041px) {
    .canva-monitor{
        height: 177px;
    }
  }

  @media (min-width: 1042px) {
    .canva-monitor{
        height: 152px;
    }
  }

  @media (min-width: 1200px) {
    .canva-monitor{
        height: 172px;
    }
  }